@import '@styles/components/Color.module.scss';

.spinnerWrapper {
  min-height: calc(100vh - 8rem);
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.spinner {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 2px;
  margin-top: 50px;
}

.innerSpinner {
  --spinner-color: #{$green-400};
  --spinner-background-color: #{$green-100};
  width: 100%;
  height: 100%;
}
